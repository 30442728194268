import React from "react"
import PropTypes from 'prop-types'
import Page from "../components/Page"
import data from '../data/index'

const IndexPage = ({ pageContext }) => (
  <>
    <Page
      name="index"
      language={pageContext.language}
      data={data}
    />
  </>
)

IndexPage.propTypes = {
  pageContext: PropTypes.shape({
    language: PropTypes.string.isRequired,
  }),
}

export default IndexPage
